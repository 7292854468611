// (function(designWidth, maxWidth) {
// 	var doc = document,
// 		docEl = doc.documentElement,
// 		remStyle = document.createElement("style"),
// 		tid;

// 	function refreshRem() {
// 		var width = docEl.getBoundingClientRect().width;
// 		maxWidth = maxWidth || 540;
// 		width>maxWidth && (width=maxWidth);
// 		var rem = width * 100 / designWidth;
// 		docEl.style.fontSize = rem + "px";
// 	}

// 	if (docEl.firstElementChild) {
// 		docEl.firstElementChild.appendChild(remStyle);
// 	} else {
// 		var wrap = doc.createElement("div");
// 		wrap.appendChild(remStyle);
// 		doc.write(wrap.innerHTML);
// 		wrap = null;
// 	}
// 	//要等 wiewport 设置好后才能执行 refreshRem，不然 refreshRem 会执行2次；
// 	refreshRem();

// 	window.addEventListener("resize", function() {
// 		clearTimeout(tid); //防止执行两次
// 		tid = setTimeout(refreshRem, 0);
// 	}, false);

// 	window.addEventListener("pageshow", function(e) {
// 		if (e.persisted) { // 浏览器后退的时候重新计算
// 			clearTimeout(tid);
// 			tid = setTimeout(refreshRem, 0);
// 		}
// 	}, false);

// 	window.addEventListener("orientationchange", function() {
//         clearTimeout(tid);
//         tid = setTimeout(refreshRem, 0);
//     }, false);

// 	if (doc.readyState === "complete") {
// 		doc.body.style.fontSize = "12px";
// 	} else {
// 		doc.addEventListener("DOMContentLoaded", function(e) {
// 			doc.body.style.fontSize = "12px";
// 		}, false);
// 	}
// })(window.innerWidth > 980 ? 1920 : 750, 4096);

function refreshRem() {
	var $winWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	var windowWidth;
	if ($winWidth >= 1200) {
		windowWidth = 1920
	} else if ($winWidth >= 768 && $winWidth < 1200) {
		windowWidth = 1200
	} else {
		windowWidth = 750
	}
	var $html = document.getElementsByTagName('html')[0];
	var htmlfont = $winWidth / windowWidth * 100 + 'px';//计算字体大小
	$html.style.fontSize = htmlfont;
}

refreshRem()
window.onresize  = function() {
	refreshRem()
}