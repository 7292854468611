
	import { defineComponent, ref, onMounted, getCurrentInstance, watch } from 'vue';
    import { useRoute } from 'vue-router'

	export default defineComponent({
		name: 'CaseDetail',
        setup() {
            const url = getCurrentInstance()?.appContext.config.globalProperties.$url
            const common = getCurrentInstance()?.appContext.config.globalProperties.$common
            const route = useRoute()

            const content = ref({})
            const prev = ref({})
            const next = ref({})
            const channel = ref({})

            watch(() => route.fullPath, () => {
                fetch(url + 'gsal_info?id=' + route.params.id).then(rsp => rsp.json()).then(data => {
                    content.value = data.data
                    prev.value = data.data.prev
                    next.value = data.data.next
                    channel.value = data.data.channel
                })
            })

            onMounted(async () => {
                const data = await fetch(url + 'gsal_info?id=' + route.params.id).then(rsp => rsp.json())
                
                content.value = data.data
                prev.value = data.data.prev
                next.value = data.data.next
                channel.value = data.data.channel
			})

            return {
                common,
                content,
                prev,
                next,
                channel
            }
        }
	});
