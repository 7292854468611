
	import { defineComponent, ref, onMounted, getCurrentInstance } from 'vue';
    import { useRoute } from 'vue-router'

    import Lightgallery from 'lightgallery/vue';
    import lgThumbnail from 'lightgallery/plugins/thumbnail';
    import lgZoom from 'lightgallery/plugins/zoom';
    let lightGallery: any = null;

	export default defineComponent({
		name: 'HonorView',
        components: {
            Lightgallery
		},
		setup() {
			const $url = getCurrentInstance()?.appContext.config.globalProperties.$url
            const route = useRoute()
			
            const navs = ref([])
			const parent = ref({})
			const banner = ref({})
            const zdList = ref([])
            const lgRef:any = ref(null)
            const handleMouseWheel = (e) => {
                const scrollLeft = lgRef.value.scrollLeft
                const offsetWidth = lgRef.value.offsetWidth
                const scrollWidth = lgRef.value.scrollWidth
                if(e.wheelDelta < 0 && (scrollLeft + offsetWidth) < scrollWidth) {
                    lgRef.value.scrollLeft = lgRef.value.scrollLeft + 50
                } else if(e.wheelDelta > 0 && scrollLeft > 0) {
                    lgRef.value.scrollLeft = lgRef.value.scrollLeft - 50
                }
            }
            const honorList = ref([])
            fetch($url + 'ryzl').then(rsp => rsp.json()).then(data => {
                honorList.value = data.data.list
            })
            const plugins = [lgThumbnail, lgZoom]
            const onLgInit = (e) => {
                lightGallery = e.instance;
            }

			onMounted(async () => {
                const navData = await fetch($url + 'navlist').then(rsp => rsp.json())
                navs.value = navData.data.list[3].sonlist

				const data = await fetch($url + 'ryzl').then(rsp => rsp.json())
                
				parent.value = data.data.parent_channel
				banner.value = data.data.channel
				zdList.value = data.data.zd_list

                lightGallery.refresh()
			})

			return {
                route,
                navs,
                parent,
				banner,
                zdList,
                honorList,
                lightGallery,
                onLgInit,
                plugins,
                lgRef,
                handleMouseWheel
			}
		}
	});
