
    import { defineComponent, ref, onMounted, getCurrentInstance } from 'vue';
    import { useRouter, useRoute } from 'vue-router'

    import { Swiper, SwiperSlide } from 'swiper/vue';
	import 'swiper/css';

    export default defineComponent({
        name: 'FooterView',
        components: {
			Swiper,
			SwiperSlide
		},
        setup() {
            const $url = getCurrentInstance()?.appContext.config.globalProperties.$url
            const router = useRouter()
            const route = useRoute()

            const FnavList = ref([])
            const linkList = ref([])
            const swiperLink = {
				loop: true,
                slidesPerView: 2,
                breakpoints: {
                    750: {
                        slidesPerView: 4
                    },
                    980: {
                        slidesPerView: 6
                    },
                    1200: {
                        slidesPerView: 8
                    }
                }
			}
            let controlledSwiper;
            const setControlledSwiper = (swiper) => {
                controlledSwiper = swiper;
            }
            const linkNext = () => {
                controlledSwiper.slideNext()
            }
            const mobile = ref({})
            const address = ref({})
            const email = ref({})
            const erweima = ref({})
            const banquan = ref(null)
            const beian = ref(null)

            onMounted(async () => {
                const dataNav = await fetch($url + 'navlist').then(rsp => rsp.json())
                FnavList.value = dataNav.data.list

				const data = await fetch($url + 'foot').then(rsp => rsp.json())
                linkList.value = data.data.links
                mobile.value = data.data.mobile
                address.value = data.data.address
                email.value = data.data.email
                erweima.value = data.data.erweima
                banquan.value = data.data.banquan
                beian.value = data.data.beian
			})

            return {
                route,
                router,
                FnavList,
                linkList,
                swiperLink,
                controlledSwiper,
                setControlledSwiper,
                linkNext,
                mobile,
                address,
                email,
                erweima,
                banquan,
                beian
            }
        }
    });
