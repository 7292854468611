
	import { defineComponent, ref, onMounted, getCurrentInstance } from 'vue';
    import { useRoute } from 'vue-router'

	export default defineComponent({
		name: 'ResearchView',
		setup() {
			const $url = getCurrentInstance()?.appContext.config.globalProperties.$url
            const route = useRoute()
			
            const navs = ref([])
			const parent = ref({})
			const banner = ref({})

			onMounted(async () => {
                const navData = await fetch($url + 'navlist').then(rsp => rsp.json())
                navs.value = navData.data.list[3].sonlist

				const data = await fetch($url + 'yfzx').then(rsp => rsp.json())
				parent.value = data.data.parent_channel
				banner.value = data.data.channel
			})

			return {
                route,
                navs,
                parent,
				banner
			}
		}
	});
