
	import { defineComponent, ref, onMounted, getCurrentInstance } from 'vue';
    import { useRoute } from 'vue-router'

	export default defineComponent({
		name: 'CultureView',
		setup() {
			const $url = getCurrentInstance()?.appContext.config.globalProperties.$url
            const route = useRoute()
			
			const parent = ref({})
			const banner = ref({})
            const navs = ref([])
            const List = ref([])
            const qywhImg = ref({})

			onMounted(async () => {
                const navData = await fetch($url + 'navlist').then(rsp => rsp.json())
                navs.value = navData.data.list[1].sonlist

				const data = await fetch($url + 'qywh').then(rsp => rsp.json())
				parent.value = data.data.parent_channel
				banner.value = data.data.channel
                List.value = data.data.list
                qywhImg.value = data.data.qywh_img
                
			})

			return {
                route,
                parent,
				banner,
                navs,
                List,
                qywhImg
			}
		}
	});
