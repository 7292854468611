
	import { defineComponent, ref, onMounted, getCurrentInstance } from 'vue';
    import { useRoute } from 'vue-router'

	export default defineComponent({
		name: 'AboutView',
		setup() {
			const $url = getCurrentInstance()?.appContext.config.globalProperties.$url
            const route = useRoute()
			
			const parent = ref({})
			const banner = ref({})
            const navs = ref([])

			onMounted(async () => {
				const data = await fetch($url + 'about').then(rsp => rsp.json())
                const navData = await fetch($url + 'navlist').then(rsp => rsp.json())
                
				parent.value = data.data.parent_channel
				banner.value = data.data.channel
                
                navs.value = navData.data.list[1].sonlist
			})

			return {
                route,
                parent,
				banner,
                navs
			}
		}
	});
