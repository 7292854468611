import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Culture from '../views/Culture.vue'
import History from '../views/History.vue'
import Contact from '../views/Contact.vue'
import Case from '../views/Case.vue'
import CaseDetail from '../views/CaseDetail.vue'
import Product from '../views/Product.vue'
import ProductDetail from '../views/ProductDetail.vue'
import Research from '../views/Research.vue'
import Wisdom from '../views/Wisdom.vue'
import Honor from '../views/Honor.vue'
import News from '../views/News.vue'
import NewsDetail from '../views/NewsDetail.vue'
import Collaboration from '../views/Collaboration.vue'
import Result from '../views/Result.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: '君华高科'
        }
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            title: '公司介绍'
        }
    },
    {
        path: '/culture',
        name: 'Culture',
        component: Culture,
        meta: {
            title: '企业文化'
        }
    },
    {
        path: '/history',
        name: 'History',
        component: History,
        meta: {
            title: '发展历史'
        }
    },
	{
		path: '/contact',
		name: 'Contact',
		component: Contact,
		meta: {
			title: '联系我们'
		}
	},
	{
		path: '/case',
		name: 'Case',
		component: Case,
		meta: {
			title: '公司案例'
		}
	},
	{
		path: '/case-detail/:id',
		name: 'CaseDetail',
		component: CaseDetail,
		meta: {
			title: '案例详情'
		}
	},
	{
		path: '/product',
		name: 'Product',
		component: Product,
		meta: {
			title: '产品'
		}
	},
	{
		path: '/product-detail/:id',
		name: 'ProductDetail',
		component: ProductDetail,
		meta: {
			title: '产品详情'
		}
	},
	{
		path: '/research',
		name: 'Research',
		component: Research,
		meta: {
			title: '研发中心'
		}
	},
	{
		path: '/wisdom',
		name: 'Wisdom',
		component: Wisdom,
		meta: {
			title: '智慧工厂'
		}
	},
	{
		path: '/honor',
		name: 'Honor',
		component: Honor,
		meta: {
			title: '荣誉资质'
		}
	},
	{
		path: '/news',
		name: 'News',
		component: News,
		meta: {
			title: '新闻中心'
		}
	},
	{
		path: '/news-detail/:id',
		name: 'NewsDetail',
		component: NewsDetail,
		meta: {
			title: '新闻详情'
		}
	},
	{
		path: '/collaboration',
		name: 'Collaboration',
		component: Collaboration,
		meta: {
			title: '渠道合作'
		}
	},
	{
		path: '/result',
		name: 'Result',
		component: Result,
		meta: {
			title: '搜索结果'
		}
	}
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
