
	import { defineComponent, ref, onMounted, getCurrentInstance, watch } from 'vue';
	import { useRoute } from 'vue-router'
	
	export default defineComponent({
		name: 'ProductView',
		setup() {
			const $url = getCurrentInstance()?.appContext.config.globalProperties.$url
			const route = useRoute()
			
			const banner = ref({})

			watch(() => route.fullPath, () => {
                fetch($url + 'productinfo&id=' + route.params.id).then(rsp => rsp.json()).then(data => {
                    banner.value = data.data
                })
            })
			
			onMounted(async () => {
				const data = await fetch($url + 'productinfo&id=' + route.params.id).then(rsp => rsp.json())
				banner.value = data.data
			})

			return {
				banner
			}
		}
	});
