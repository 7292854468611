
	import { defineComponent, reactive, ref, onMounted, onBeforeUnmount, getCurrentInstance, nextTick } from 'vue';
	
	import { Swiper, SwiperSlide } from 'swiper/vue';
	import { Autoplay, Pagination, Navigation } from "swiper";
	import 'swiper/css';
	import 'swiper/css/pagination';
	import "swiper/css/navigation";
	import scrollReveal from 'scrollreveal';

	export default defineComponent({
		name: 'HomeView',
		components: {
			Swiper,
			SwiperSlide
		},
		setup() {
			const $url = getCurrentInstance()?.appContext.config.globalProperties.$url
			const common = getCurrentInstance()?.appContext.config.globalProperties.$common
			
			const bannerList = ref([])
			const swiperBanner = {
				autoplay: {
					delay: 4000, 
					disableOnInteraction: false 
				},
				speed: 1000,
				loop: true, 
				pagination: {
					clickable: true 
				}
			}
			const modules = [Autoplay, Pagination, Navigation]
			const about = ref({})
			const aboutRjzl = ref({})
			const aboutZhgc = ref({})
			const aboutFwrq = ref({})
			const aboutFwst = ref({})
			const spanItemRef: any = ref<HTMLElement[]>()
			const spanRef1: any = ref(null)
			const spanRef2: any = ref(null)
			const spanRef3: any = ref(null)
			const spanRef4: any = ref(null)
			const num1 = ref(0)
			const num2 = ref(0)
			const num3 = ref(0)
			const num4 = ref(0)
			const productList = ref([])
			const swiperPro = {
				navigation: true
			}
			const modulesPro = [Navigation]
			const newsList = ref([])
			const newsIndex = ref(0)
			const newsMouseover = (index) => {
				newsIndex.value = index
			}
			const indexVideo = ref({})
			const videoRef:any = ref<HTMLElement>()
			const onScroll = () => {
				let scrollTop = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset
				if (scrollTop >= videoRef.value.offsetTop - (window.innerHeight / 2)) {
					videoRef.value.classList.add('ed')
				} else {
					videoRef.value.classList.remove('ed')
				}
			}
			let is_running1 = true
			let is_running2 = true
			let is_running3 = true
			let is_running4 = true
			let timer1;
			let timer2;
			let timer3;
			let timer4;
			let now1 = 0;
			let now2 = 0;
			let now3 = 0;
			let now4 = 0;
			const countScroll = () => {
				let scrollTop = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset
				if (scrollTop < spanItemRef.value.offsetTop && scrollTop > spanItemRef.value.offsetTop - window.innerHeight) {
					if (is_running1) {
						is_running1 = false
						let val1 = Number(spanRef1.value.getAttribute('data-num'))
						timer1 = setInterval(() => {
							if (val1 == now1) {
								clearInterval(timer1)
								return false
							}
							now1 ++
							num1.value = now1
						}, 1000 / val1)
					}
					if (is_running2) {
						is_running2 = false
						let val2 = Number(spanRef2.value.getAttribute('data-num'))
						timer2 = setInterval(() => {
							if (val2 == now2) {
								clearInterval(timer2)
								return false
							}
							now2 ++
							num2.value = now2
						}, 1000 / val2)
					}
					if (is_running3) {
						is_running3 = false
						let val3 = Number(spanRef3.value.getAttribute('data-num'))
						timer3 = setInterval(() => {
							if (val3 == now3) {
								clearInterval(timer3)
								return false
							}
							now3 ++
							num3.value = now3
						}, 1000 / val3)
					}
					if (is_running4) {
						is_running4 = false
						let val4 = Number(spanRef4.value.getAttribute('data-num'))
						timer4 = setInterval(() => {
							if (val4 == now4) {
								clearInterval(timer4)
								return false
							}
							now4 ++
							num4.value = now4
						}, 1000 / val4)
					}
				} else {
					num1.value = now1 = 0
					num2.value = now2 = 0
					num3.value = now3 = 0
					num4.value = now4 = 0
					is_running1 = true
					is_running2 = true
					is_running3 = true
					is_running4 = true
				}
			}
			const videoShow = ref(false)
			const vdRef:any = ref<HTMLElement>()
			const openVideo = () => {
				videoShow.value = true
			}
			const closeVideo = () => {
				vdRef.value.pause()
				videoShow.value = false
			}
			const qywh = ref({})
			const rczp = ref({})
			const lxwm = ref({})
			const state = reactive({
				scrollReveal: scrollReveal()
			})
			const setScrollReveal = () => {
				state.scrollReveal.reveal('.s-r-bottom', {
					delay: 400,
					distance: '40px',
					duration: 600,
					reset: true,
					mobile: false,
					origin: 'bottom',
					easing: 'ease-out',
					opacity: 0
				})
				state.scrollReveal.reveal('.s-r-left', {
					delay: 200,
					distance: '40px',
					duration: 600,
					reset: true,
					mobile: false,
					origin: 'left',
					easing: 'ease-out',
					opacity: 0
				})
				state.scrollReveal.reveal('.s-r-right', {
					delay: 300,
					distance: '40px',
					duration: 600,
					reset: true,
					mobile: false,
					origin: 'right',
					easing: 'ease-out',
					opacity: 0
				})
			}
			

			onMounted(async () => {
				const data = await fetch($url + 'index').then(rsp => rsp.json())

				bannerList.value = data.data.bannerList
				about.value = data.data.about
				aboutRjzl.value = data.data.about.rjzl
					num1.value = data.data.about.rjzl.text
				aboutZhgc.value = data.data.about.zhgc
					num2.value = data.data.about.zhgc.text
				aboutFwrq.value = data.data.about.fwrq
					num3.value = data.data.about.fwrq.text
				aboutFwst.value = data.data.about.fwst
					num4.value = data.data.about.fwst.text
				productList.value = data.data.product.list
				newsList.value = data.data.news.sonlist
				indexVideo.value = data.data.index_video
				qywh.value = data.data.qywh
				rczp.value = data.data.rczp
				lxwm.value = data.data.lxwm

				window.addEventListener("scroll", onScroll)
				window.addEventListener("scroll", countScroll)

				nextTick(() => {
					setScrollReveal()
				})
			})
			onBeforeUnmount(() => {
				window.removeEventListener("scroll", onScroll)
				window.removeEventListener("scroll", countScroll)

				state.scrollReveal.destroy();
			})

			return {
				common,
				bannerList,
				swiperBanner,
				modules,
				about,
				aboutRjzl,
				aboutZhgc,
				aboutFwrq,
				aboutFwst,
				spanItemRef,
				spanRef1,
				spanRef2,
				spanRef3,
				spanRef4,
				num1,
				num2,
				num3,
				num4,
				productList,
				swiperPro,
				modulesPro,
				newsList,
				newsIndex,
				newsMouseover,
				indexVideo,
				videoRef,
				videoShow,
				openVideo,
				closeVideo,
				vdRef,
				qywh,
				rczp,
				lxwm,
				setScrollReveal
			}
		}
	});
