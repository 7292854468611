import 'babel-polyfill'
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import MetaInfo from 'vue-meta-info'
import VueScrollTo from "vue-scrollto"

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

import '../static/css/reset.css'
import '../static/css/common.css'
import '../static/js/flexible.js'
import common from '../static/js/common'

router.beforeEach((to, from, next) => {
    // if (to.meta.title) {
    //     document.title = to.meta.title
    // }
    
    //回到顶部
    window.scrollTo(0,0);
    document.documentElement.scrollTop = 0
    next()
})

const app = createApp(App).component('Header', Header).component('Footer', Footer)

app.config.globalProperties.$url = 'https://admin.jhgk.net/api/index/'
app.config.globalProperties.$common = common
app.use(router)
app.use(MetaInfo)
app.use(ElementPlus)
app.use(VueScrollTo, {
    container: "body",
    duration: 500,
    easing: "ease",
    offset: -110,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
})
app.mount('#app')