
    import { defineComponent, onMounted, getCurrentInstance, reactive, ref, watch } from 'vue';
    import { useRouter, useRoute } from 'vue-router'

    import { ElMessage } from 'element-plus'

    export default defineComponent({
        name: 'HeaderView',
        setup() {
            const url = getCurrentInstance()?.appContext.config.globalProperties.$url
            const common = getCurrentInstance()?.appContext.config.globalProperties.$common
            const router = useRouter()
            const route = useRoute()

            type options = {
                List: any[],
                value: string
            }
            const state: options = reactive({
                List: [],
                value: ''
			})
            const currentPage = ref(1)
            const pageSize = ref(8)
            const totalNum = ref(1)
            const handleCurrentChange = (val:number) => {
                currentPage.value = val
                
                fetch(url + 'search?key=' + route.query.key + '&page=' + val).then(rsp => rsp.json()).then(data => {
                    state.List = data.data.data
                })
            }
            const Search = () => {
                if (!state.value) {
                    ElMessage('请输入关键字！')
                    return false
                }
                router.push('/result?key=' + state.value)
            }
            const SearchEnter = () => {
                if (!state.value) {
                    ElMessage('请输入关键字！')
                    return false
                }
                router.push('/result?key=' + state.value)
            }

            watch(() => route.fullPath, () => {
                fetch(url + 'search?key=' + route.query.key).then(rsp => rsp.json()).then(data => {
                    data.data.data.forEach(cur => {
                    if (cur.model_id == 1) {
                        cur.url = 'case'
                    }
                    if (cur.model_id == 4) {
                        cur.url = '/product-detail/' + cur.id
                    }
                    if (cur.model_id == 6) {
                        cur.url = '/news-detail/' + cur.id
                    }
                })
                    state.List = data.data.data
                    totalNum.value = data.data.total
                })
            })

            onMounted(async () => {
				const data = await fetch(url + 'search?key=' + route.query.key).then(rsp => rsp.json())
                data.data.data.forEach(cur => {
                    if (cur.model_id == 1) {
                        cur.url = 'case'
                    }
                    if (cur.model_id == 4) {
                        cur.url = '/product-detail/' + cur.id
                    }
                    if (cur.model_id == 6) {
                        cur.url = '/news-detail/' + cur.id
                    }
                })
                state.List = data.data.data
                totalNum.value = data.data.total
			})

            return {
                common,
                route,
                state,
                currentPage,
                pageSize,
                totalNum,
                handleCurrentChange,
                Search,
                SearchEnter
            }
        }
    });
