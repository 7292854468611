
    import { defineComponent, onMounted, getCurrentInstance, ref, watch } from 'vue';
    import { useRouter, useRoute } from 'vue-router'

    import { ElMessage } from 'element-plus'

    export default defineComponent({
        name: 'HeaderView',
        setup() {
            const $url = getCurrentInstance()?.appContext.config.globalProperties.$url
            const router = useRouter()
            const route = useRoute()
            
            const navlist = ref([])
            const searcShow = ref(false)
            const openSearchBox = () => {
                searcShow.value = true
            }
            const closeSearchBox = () => {
                searcShow.value = false
            }
            const value = ref(null)
            const Search = () => {
                if (!value.value) {
                    ElMessage('请输入关键字！')
                    return false
                }
                router.push('/result?key=' + value.value)
            }
            const SearchEnter = () => {
                if (!value.value) {
                    ElMessage('请输入关键字！')
                    return false
                }
                router.push('/result?key=' + value.value)
            }
            const menuShow = ref(false)

            watch(() => route.fullPath, () => {
                menuShow.value = false
            })

            onMounted(async () => {
				const data = await fetch($url + 'navlist').then(rsp => rsp.json())
                
                data.data.list.forEach(cur => { cur.show = false })
                navlist.value = data.data.list
			})

            return {
                route,
                navlist,
                searcShow,
                openSearchBox,
                closeSearchBox,
                value,
                Search,
                SearchEnter,
                menuShow
            }
        }
    });
