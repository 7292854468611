
	import { defineComponent, ref, onMounted, getCurrentInstance } from 'vue';
	
	export default defineComponent({
		name: 'ProductView',
		setup() {
			const $url = getCurrentInstance()?.appContext.config.globalProperties.$url
			
			const banner = ref({})
			const List = ref([])
			
			
			onMounted(async () => {
				const data = await fetch($url + 'products').then(rsp => rsp.json())
				
				banner.value = data.data.channel
				List.value = data.data.list
			})

			return {
				banner,
				List
			}
		}
	});
