
	import { defineComponent, ref, onMounted, getCurrentInstance } from 'vue';
	import { useRoute } from 'vue-router'

	export default defineComponent({
		name: 'WisdomView',
		setup() {
			const $url = getCurrentInstance()?.appContext.config.globalProperties.$url
			const route = useRoute()
			
			const navs = ref([])
			const parent = ref({})
			const banner = ref({})
			const video = ref({})
			const videoShow = ref(false)
			const vdRef:any = ref<HTMLElement>()
			const openVideo = () => {
				videoShow.value = true
			}
			const closeVideo = () => {
				vdRef.value.pause()
				videoShow.value = false
			}

			onMounted(async () => {
				const navData = await fetch($url + 'navlist').then(rsp => rsp.json())
                navs.value = navData.data.list[3].sonlist

				const data = await fetch($url + 'zhgc').then(rsp => rsp.json())
                
				parent.value = data.data.parent_channel
				banner.value = data.data.channel
				video.value = data.data.video
			})

			return {
				route,
                navs,
                parent,
				banner,
				video,
				videoShow,
				openVideo,
				closeVideo,
				vdRef
			}
		}
	});
