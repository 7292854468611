//去除标签
function deleteTag(str) {
    if (str) {
        return str.replace(/<[^>]+>/g, '')
    }
}

// 对时间格式优化
function timestampPadStart(str) {
    str = String(str)
    return str.padStart(2, '0')
}
  
// 处理时间戳
function formatTime(ts, y = true, m = true, d = true) {
    const date = new Date(Number(ts))
    const YYYY = timestampPadStart(date.getFullYear())
    const MM = timestampPadStart(date.getMonth() + 1)
    const DD = timestampPadStart(date.getDate())
  
    if (y && m && d) return `${YYYY}-${MM}-${DD}`
    if (m) return `${MM}`
}

export default {
    deleteTag,
    formatTime
}
  
  