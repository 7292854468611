
	import { defineComponent, ref, onMounted, getCurrentInstance } from 'vue';

    import { ElMessage } from 'element-plus'
    import { Swiper, SwiperSlide } from 'swiper/vue';
	import 'swiper/css';

	export default defineComponent({
		name: 'CollaborationView',
        components: {
			Swiper,
			SwiperSlide
		},
		setup() {
			const $url = getCurrentInstance()?.appContext.config.globalProperties.$url
			
			const banner = ref({})
			const wmdys = ref({})
            const youIndex = ref(0)
            const tabYou = (index) => {
                youIndex.value = index
            }
            const hzzc = ref({})
            const swiperClla:object = {
				loop: true,
                loopedSlides: 9,
                slidesPerView: 'auto',
                centeredSlides: true,
                centerInsufficientSlides: true
			}
            let controlledSwiperClla;
            const setControlledSwiperClla = (swiper) => {
                controlledSwiperClla = swiper;
            }
            const cllaPrev = () => {
                controlledSwiperClla.slidePrev(500, false)
            }
            const cllaNext = () => {
                controlledSwiperClla.slideNext(500, false)
            }
            const hzlc = ref({})
            const ljcwhb = ref({})
            const name = ref('')
            const mobile = ref('')
            const company = ref('')
            const weixin = ref('')
            const content = ref('')
            const Submit = async() => {
                await fetch($url + 'tijiao', {
                    method: 'post',
                    body: JSON.stringify({
                        name: name.value,
                        mobile: mobile.value,
                        company: company.value,
                        weixin: weixin.value,
                        content: content.value
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(rsp => rsp.json()).then(data => {
                    if (data.code == 0) {
                        ElMessage.error(data.msg)
                        return
                    }
                    if (data.code == 1) {
                        ElMessage({
                            message: data.msg,
                            type: 'success'
                        })
                        name.value = ''
                        mobile.value = ''
                        company.value = ''
                        weixin.value = ''
                        content.value = ''
                    }
                })
            }
            const Reset = () => {
                name.value = ''
                mobile.value = ''
                company.value = ''
                weixin.value = ''
                content.value = ''
            }

			onMounted(async () => {
				const data = await fetch($url + 'qdhz').then(rsp => rsp.json())
				banner.value = data.data.channel
				wmdys.value = data.data.wmdys
				hzzc.value = data.data.hzzc
				hzlc.value = data.data.hzlc
				ljcwhb.value = data.data.ljcwhb
			})

			return {
                parent,
				banner,
                wmdys,
                youIndex,
                tabYou,
                hzzc,
                swiperClla,
                setControlledSwiperClla,
                controlledSwiperClla,
                cllaPrev,
                cllaNext,
                hzlc,
                ljcwhb,
                name,
                mobile,
                company,
                weixin,
                content,
                Submit,
                Reset
			}
		}
	});
