
	import { defineComponent, ref, onMounted, getCurrentInstance } from 'vue';
	import { useRoute } from 'vue-router'
	
	import { Swiper, SwiperSlide } from 'swiper/vue';
	import { FreeMode, Thumbs } from "swiper";
	import 'swiper/css';
	import "swiper/css/navigation";

	export default defineComponent({
		name: 'HistoryView',
		components: {
			Swiper,
			SwiperSlide
		},
		setup() {
			const $url = getCurrentInstance()?.appContext.config.globalProperties.$url
			const route = useRoute()
			
			const parent = ref({})
			const banner = ref({})
			const navs = ref([])
			const List = ref([])
			const swiperHistoryThumbs = {
				slidesPerView: 3,
				centerInsufficientSlides: true,
				freeMode: true,
				watchSlidesProgress: true,
				breakpoints: {
					750: {
						slidesPerView: 5
					},
					980: {
						slidesPerView: 7
					}
				}
			}
			const modules = [FreeMode, Thumbs]
			const thumbsSwiper = ref(null);
			const setThumbsSwiper = (swiper) => {
				thumbsSwiper.value = swiper;
			}
			let controlledSwiper;
			const setControlledSwiper = (swiper) => {
				controlledSwiper = swiper;
			}
			const swiperPrev = () => {
				controlledSwiper.slidePrev()
			}
			const swiperNext = () => {
				controlledSwiper.slideNext()
			}

			onMounted(async () => {
				const data = await fetch($url + 'fzlc').then(rsp => rsp.json())
				const navData = await fetch($url + 'navlist').then(rsp => rsp.json())
                
				parent.value = data.data.parent_channel
				banner.value = data.data.channel
				List.value = data.data.list

				navs.value = navData.data.list[1].sonlist
			})

			return {
				route,
                parent,
				banner,
				navs,
				List,
				swiperHistoryThumbs,
				modules,
				setThumbsSwiper,
				thumbsSwiper,
				controlledSwiper,
				setControlledSwiper,
				swiperPrev,
				swiperNext
			}
		}
	});
