
	import { defineComponent, ref, onMounted, getCurrentInstance, watch } from 'vue';
    import { useRoute } from 'vue-router'

	export default defineComponent({
		name: 'AboutView',
		setup() {
			const $url = getCurrentInstance()?.appContext.config.globalProperties.$url
            const common = getCurrentInstance()?.appContext.config.globalProperties.$common
            const route = useRoute()
			
			const parent = ref({})
			const banner = ref({})
            const navList = ref([])
            const List = ref([])
            const currentPage = ref(1)
            const pageSize = ref(0)
            const totalNum = ref(0)
            const handleCurrentChange = (val:number) => {
                currentPage.value = val
                
                fetch($url + 'news?channel_id=' + route.query.id + '&page=' + val).then(rsp => rsp.json()).then(data => {
                    List.value = data.data.list.data
                })
            }

			watch(() => route.fullPath, () => {
                fetch($url + 'news&channel_id=' + route.query.id).then(rsp => rsp.json()).then(data => {
					banner.value = data.data.channel
					navList.value = data.data.parent_channel.sonlist
					List.value = data.data.list.data
					pageSize.value = data.data.list.per_page
					totalNum.value = data.data.list.total
                })
            })

			onMounted(async () => {
				const data = await fetch($url + 'news&channel_id=' + route.query.id).then(rsp => rsp.json())
                
				parent.value = data.data.parent_channel
				banner.value = data.data.channel
				navList.value = data.data.parent_channel.sonlist
				List.value = data.data.list.data
				pageSize.value = data.data.list.per_page
				totalNum.value = data.data.list.total
			})

			return {
                common,
                route,
                parent,
				banner,
                navList,
                List,
                currentPage,
                pageSize,
                totalNum,
                handleCurrentChange
			}
		}
	});
